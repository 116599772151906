import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import styles from './Database.module.css';
import DatabaseAdvancedSearch from './DatabaseAdvancedSearch';
import DatabaseDetailItem from './DatabaseDetailItem';
import DatabaseSearchByAdvancedKeyword from './DatabaseSearchByAdvancedKeyword';
import DatabaseSearchByIndexId from './DatabaseSearchByIndexId';
import DatabaseSearchByItemType from './DatabaseSearchByItemType';
import DatabaseSearchByKeyword from './DatabaseSearchByKeyword';
import DatabaseTop from './DatabaseTop';

interface Props {
    lang: MultiLang;
}

const Database = (props: Props) => {
    const { lang } = props;
    return (
        <div className={styles.database}>
            <Helmet>
                <title>{Functions.mlang('[en]Database[/en][ja]データベース[/ja]', lang)} - {Functions.siteTitle(lang)}</title>
            </Helmet>
            <Switch>
                <Route exact path="/database" render={(props: RouteComponentProps) => <DatabaseTop lang={lang} {...props} />} />
                <Route exact path="/database/item/:id" render={(props: RouteComponentProps<{ id: string }>) => <DatabaseDetailItem lang={lang} {...props} />} />
                <Route exact path="/database/item/id/:doi" render={(props: RouteComponentProps<{ doi: string }>) => <DatabaseDetailItem lang={lang} {...props} />} />
                <Route exact path="/database/advanced" render={(props: RouteComponentProps) => <DatabaseAdvancedSearch lang={lang} {...props} />} />
                <Route exact path="/database/list" render={(props: RouteComponentProps) => <DatabaseSearchByIndexId lang={lang} {...props} />} />
                <Route exact path="/database/list/:id" render={(props: RouteComponentProps<{ id: string }>) => <DatabaseSearchByIndexId lang={lang} {...props} />} />
                <Route exact path="/database/search" render={(props: RouteComponentProps) => <DatabaseSearchByKeyword lang={lang} {...props} />} />
                <Route exact path="/database/search/advanced" render={(props: RouteComponentProps) => <DatabaseSearchByAdvancedKeyword lang={lang} {...props} />} />
                <Route exact path="/database/search/itemtype/:itemType" render={(props: RouteComponentProps<{ itemType: string }>) => <DatabaseSearchByItemType lang={lang} {...props} />} />
                <Route exact path="/database/search/itemtype/:itemType/:subItemType" render={(props: RouteComponentProps<{ itemType: string; subItemType: string }>) => <DatabaseSearchByItemType lang={lang} {...props} />} />
                <Route component={PageNotFound} />
            </Switch>
        </div>
    );
}

export default Database;
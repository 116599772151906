import React from 'react';
import { MultiLang } from '../../../config';
import { ItemDataSubType, ItemDataSubTypes } from '../../lib/ItemUtil';

interface DataTypeProps {
    lang: MultiLang;
    type: ItemDataSubType;
}

const DataType = (props: DataTypeProps) => {
    const { type } = props;
    const subtype = ItemDataSubTypes.find((value) => { return value.type === type; });
    if (typeof subtype === 'undefined') {
        return null;
    }
    return (<span>{subtype.label}</span>);
}

const DataUtil = {
    DataType,
}

export default DataUtil;
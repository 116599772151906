import TopBase, { TopBaseProps } from '../lib/TopBase';
import iconFile from '../../assets/images/icon_binder.gif';

class BinderTop extends TopBase {

    constructor(props: TopBaseProps) {
        super(props);
        this.type = 'binder';
        this.label = 'Binder';
        this.icon = iconFile;
        this.description = '[en]Binder collection.[/en][ja]バインダー[/ja]';
    }
}

export default BinderTop;
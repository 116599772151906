import React from 'react';
import { MultiLang } from '../../../config';

interface PubmedLinkProps {
    lang: MultiLang;
    pubmedId: string;
}

const PubmedLink = (props: PubmedLinkProps) => {
    const { pubmedId } = props;
    if (pubmedId === '') {
        return null;
    }
    const url = 'https://www.ncbi.nlm.nih.gov/pubmed/' + pubmedId;
    return (<a href={url} target="_blank" rel="noopener noreferrer">{pubmedId}</a>);
}

const PaperUtil = {
    PubmedLink,
}

export default PaperUtil;
import BinderAdvancedSearch from './BinderAdvancedSearch';
import BinderDetail from './BinderDetail';
import BinderList from './BinderList';
import BinderTop from './BinderTop';

const ItemTypeBinder = {
    Top: BinderTop,
    List: BinderList,
    Detail: BinderDetail,
    AdvancedSearch: BinderAdvancedSearch,
};

export default ItemTypeBinder;

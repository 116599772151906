import React from 'react';
import { MultiLang } from '../../../../config';
import Description from './Description';

interface Props {
    lang: MultiLang;
    readme: string;
}

const Readme = (props: Props) => {
    const { lang, readme } = props;
    return <Description lang={lang} description={readme} className="readme" />;
}

export default Readme;
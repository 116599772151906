import React, { ChangeEvent, Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import Loading from '../../common/lib/Loading';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import ItemType from '../item-type';
import styles from './DatabaseListItem.module.css';
import { Item, SearchFunc, SearchResult, SortCondition, SortConditionLimit, SortConditionOrderBy, SortConditionOrderDir } from './ItemUtil';

const SORT_CONDITION_DEFAULT: SortCondition = {
    limit: 20,
    page: 1,
    orderBy: 'title',
    orderDir: 1
};
const SORT_CONDITION_RANGE_ORDER_BY = ['title', 'doi', 'last_update_date', 'creation_date', 'publication_date'];
const SORT_CONDITION_RANGE_ORDER_DIR = ['0', '1'];
const SORT_CONDITION_RANGE_LIMIT = ['20', '50', '100'];

interface Props extends RouteComponentProps {
    lang: MultiLang;
    url: string;
    search: SearchFunc
}

interface State {
    loading: boolean;
    condition: SortCondition;
    result: SearchResult;
}

class DatabaseListItem extends Component<Props, State> {

    private isActive = false;

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            condition: this.getSortConditionByQuery(this.props.location.search),
            result: { total: 0, data: [] }
        };
        this.handleSelectOrderby = this.handleSelectOrderby.bind(this);
        this.handleSelectItemcount = this.handleSelectItemcount.bind(this);
    }

    componentDidMount() {
        this.isActive = true;
        this.props.search(this.state.condition, (result) => {
            if (this.isActive) {
                this.setState({ loading: false, result });
            }
        });
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const condition = this.getSortConditionByQuery(this.props.location.search);
        if (prevProps.url !== this.props.url || this.isConditionChanged(prevState.condition, condition)) {
            this.props.search(condition, (result) => {
                if (this.isActive) {
                    this.setState({ loading: false, result, condition });
                }
            });
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    handleSelectOrderby(event: ChangeEvent<HTMLSelectElement>) {
        const value = event.target.value;
        const url = this.getListUrl({ orderby: value });
        this.props.history.push(url);
    }

    handleSelectItemcount(event: ChangeEvent<HTMLSelectElement>) {
        const value = parseInt(event.target.value, 10);
        const url = this.getListUrl({ limit: value });
        this.props.history.push(url);
    }

    isConditionChanged(prev: SortCondition, next: SortCondition) {
        return prev.limit !== next.limit || prev.orderBy !== next.orderBy || prev.orderDir !== next.orderDir || prev.page !== next.page;
    }

    getListUrl(query: { orderby?: string, order_dir?: number, limit?: number, page?: number }) {
        const newOrderBy: string = typeof query.orderby !== 'undefined' ? query.orderby : this.state.condition.orderBy;
        const newOrderDir: number = typeof query.order_dir !== 'undefined' ? query.order_dir : this.state.condition.orderDir;
        const newLimit: number = typeof query.limit !== 'undefined' ? query.limit : this.state.condition.limit;
        let newPage: number = typeof query.page !== 'undefined' ? query.page : this.state.condition.page;
        if (newLimit !== this.state.condition.limit || newOrderBy !== this.state.condition.orderBy) {
            newPage = SORT_CONDITION_DEFAULT.page;
        }
        const params = new URLSearchParams({
            orderby: newOrderBy,
            order_dir: String(newOrderDir),
            itemcount: String(newLimit),
            page: String(newPage),
        });
        const join = this.props.url.indexOf('?') < 0 ? '?' : '&'
        return this.props.url + join + params.toString();
    }

    getDefaultSortCondition() {
        return Object.assign({}, SORT_CONDITION_DEFAULT);
    };

    getSortConditionByQuery(queryString: string) {
        const query = new URLSearchParams(queryString);
        const condition = this.getDefaultSortCondition();
        const orderby = query.get('orderby');
        if (orderby !== null && SORT_CONDITION_RANGE_ORDER_BY.includes(orderby)) {
            condition.orderBy = orderby as SortConditionOrderBy;
        }
        const order_dir = query.get('order_dir');
        if (order_dir !== null && SORT_CONDITION_RANGE_ORDER_DIR.includes(order_dir)) {
            condition.orderDir = parseInt(order_dir, 10) as SortConditionOrderDir;
        }
        const itemcount = query.get('itemcount');
        if (itemcount !== null && SORT_CONDITION_RANGE_LIMIT.includes(itemcount)) {
            condition.limit = parseInt(itemcount, 10) as SortConditionLimit;
        }
        const page = query.get('page');
        if (page !== null && page.match(/^\d+$/) !== null) {
            condition.page = parseInt(page, 10);
        }
        return condition;
    }

    renderSortCriteria() {
        const { lang } = this.props;
        let orderDir;
        if (this.state.condition.orderDir !== SortConditionOrderDir.ASC) {
            const url = this.getListUrl({ order_dir: SortConditionOrderDir.ASC });
            orderDir = (<span>
                &#9660;&nbsp;<Link to={url}>&#9650;</Link>
            </span>);
        } else {
            const url = this.getListUrl({ order_dir: SortConditionOrderDir.DESC });
            orderDir = (<span>
                <Link to={url}>&#9660;</Link>&nbsp;&#9650;
            </span>);
        }
        return (
            <div className={styles.sortCriteria}>
                <table className="listTable">
                    <tbody>
                        <tr>
                            <td className="odd">
                                Order by&nbsp;
                                <select value={this.state.condition.orderBy} onChange={this.handleSelectOrderby}>
                                    <option value="title">{Functions.mlang('[en]Title[/en][ja]タイトル[/ja]', lang)}</option>
                                    <option value="doi">ID</option>
                                    <option value="last_update_date">{Functions.mlang('[en]Last Modified Date[/en][ja]最終更新日[/ja]', lang)}</option>
                                    <option value="creation_date">{Functions.mlang('[en]Created Date[/en][ja]作成日[/ja]', lang)}</option>
                                    <option value="publication_date">{Functions.mlang('[en]Date[/en][ja]日付[/ja]', lang)}</option>
                                </select>
                                &nbsp;{orderDir}
                            </td>
                            <td className="odd">
                                No.Item per page&nbsp;
                                <select value={this.state.condition.limit} onChange={this.handleSelectItemcount}>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    renderPageNavi(result: SearchResult) {
        const maxPage = Math.floor(result.total / this.state.condition.limit) + (result.total % this.state.condition.limit !== 0 ? 1 : 0);
        const link = (title: string, page: number, id: string) => {
            if (page === 0 || page === this.state.condition.page) {
                return (<span key={id} className={styles.pageNaviItem}>{title}</span>);
            }
            const url: string = this.getListUrl({ page: page });
            return (<Link key={id} className={styles.pageNaviItem} to={url}>{title}</Link>);
        }
        let startPage = (this.state.condition.page - 4) > 1 ? this.state.condition.page - 4 : 1;
        const endPage = (startPage + 9) > maxPage ? maxPage : startPage + 9;
        if ((endPage - startPage) < 9) {
            startPage = (endPage - 9) > 0 ? endPage - 9 : 1;
        }
        let pageLinks: JSX.Element[] = [];
        pageLinks.push(link('PREV', this.state.condition.page - 1, 'p'));
        for (let i = startPage; i <= endPage; i++) {
            pageLinks.push(link(String(i), i, String(i)));
        }
        pageLinks.push(link('NEXT', this.state.condition.page >= maxPage ? 0 : this.state.condition.page + 1, 'n'));
        return (
            <div className={styles.pageNavi}>
                {pageLinks}
            </div>
        );
    }

    render() {
        const { lang } = this.props;
        if (this.state.loading) {
            return <Loading />;
        }
        const result = this.state.result;
        if (result.data.length === 0) {
            return (
                <p>No items found.</p>
            );
        }
        const startNum = 1 + this.state.condition.limit * (this.state.condition.page - 1);
        let endNum = this.state.condition.limit * this.state.condition.page;
        if (endNum > result.total) {
            endNum = result.total;
        }
        const pageNavi = this.renderPageNavi(result);
        const items = result.data.map((item: Item, idx) => {
            const evenodd = idx % 2 === 0 ? 'even' : 'odd';
            return (<tr key={item.item_id} className={evenodd}><td><ItemType.List lang={lang} item={item} /></td></tr>);
        });
        return (
            <>
                {this.renderSortCriteria()}
                <p>{startNum} - {endNum} of {result.total} Items</p>
                {pageNavi}
                <table className="listTable">
                    <tbody>
                        {items}
                    </tbody>
                </table>
                {pageNavi}
            </>
        );
    }
}

export default withRouter(DatabaseListItem);
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { MultiLang } from '../config';
import Functions from '../functions';
import DatabaseListItem from './lib/DatabaseListItem';
import ItemUtil, { KeywordSearchType, SearchCallbackFunc, SortCondition } from './lib/ItemUtil';

interface Props extends RouteComponentProps {
    lang: MultiLang;
}

interface State {
    type: KeywordSearchType;
    keyword: string;
}

class DatabaseSearchByKeyword extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const { type, keyword } = ItemUtil.getSearchKeywordByQuery(this.props.location.search);
        this.state = { type, keyword };
        this.searchFunc = this.searchFunc.bind(this);
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const { type, keyword } = ItemUtil.getSearchKeywordByQuery(nextProps.location.search);
        if (prevState.type !== type || prevState.keyword !== keyword) {
            return { type, keyword };
        }
        return null;
    }

    getUrl() {
        return ItemUtil.getSearchByKeywordUrl(this.state.type, this.state.keyword);
    }

    searchFunc(condition: SortCondition, func: SearchCallbackFunc) {
        if (this.state.keyword === '') {
            const res = { total: 0, data: [] };
            func(res);
        } else {
            ItemUtil.getListByKeyword(this.state.type, this.state.keyword, condition, func);
        }
    }

    render() {
        const { lang } = this.props;
        const baseUrl = this.getUrl();
        return (
            <div className="list">
                <h3>{Functions.mlang('[en]Listing item[/en][ja]アイテム一覧[/ja]', lang)}</h3>
                <p>{Functions.mlang('[en]Search Keyword[/en][ja]検索キーワード[/ja]', lang)} : {this.state.keyword}</p>
                <DatabaseListItem lang={lang} url={baseUrl} search={this.searchFunc} />
            </div>
        );
    }
}

export default DatabaseSearchByKeyword;

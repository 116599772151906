import React, { Component } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { MultiLang } from '../config';
import PageNotFound from './lib/PageNotFound';

interface Params {
    module: string;
    pathname: string;
}

interface Props extends RouteComponentProps<Params> {
    lang: MultiLang;
}

class XoopsPathRedirect extends Component<Props> {

    getRedirectUrl() {
        const { pathname } = this.props.location;
        switch (pathname || '') {
            case '/index.php': {
                return '/';
            }
        }
        return '';
    }

    render() {
        const { lang } = this.props;
        if (this.props.location.pathname === '/') {
            return null;
        }
        const url = this.getRedirectUrl();
        if (url === '') {
            return <PageNotFound lang={lang} />;
        }
        return <Redirect to={url} />;
    }
}

export default XoopsPathRedirect;

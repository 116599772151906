import moment from 'moment';
import React from 'react';
import { MultiLang } from '../../../../config';

interface Props {
    lang: MultiLang;
    date: number;
    onlyDate?: boolean
}

const DateTime = (props: Props) => {
    const { date, onlyDate } = props;
    const d = moment(new Date(date * 1000));
    let format = 'MMM D, Y';
    if (typeof onlyDate === 'undefined' || !onlyDate) {
        format += ' HH:mm:ss';
    }
    return <span>{d.format(format)}</span>;
}

export default DateTime;

import React from 'react';
import Functions from '../../../functions';
import { ItemBook } from '../../lib/ItemUtil';
import DetailBase from '../lib/DetailBase';
import ItemTypeField from '../lib/field';

class BookDetail extends DetailBase {

    getFields() {
        const { lang } = this.props;
        const item = this.props.item as ItemBook;
        return [
            { label: 'ID', value: item.doi },
            { label: '[en]Language[/en][ja]言語[/ja]', value: <ItemTypeField.Language lang={lang} itemLang={item.lang} /> },
            { label: '[en]Book Title[/en][ja]著書名[/ja]', value: Functions.mlang(item.title, lang) },
            { label: '[en]Free Keywords[/en][ja]フリーキーワード[/ja]', value: <ItemTypeField.FreeKeyword lang={lang} keyword={item.keyword} /> },
            { label: '[en]Description[/en][ja]概要[/ja]', value: <ItemTypeField.Description lang={lang} description={item.description} /> },
            { label: '[en]Last Modified Date[/en][ja]最終更新日[/ja]', value: <ItemTypeField.DateTime lang={lang} date={item.last_update_date} /> },
            { label: '[en]Created Date[/en][ja]作成日[/ja]', value: <ItemTypeField.DateTime lang={lang} date={item.creation_date} /> },
            { label: '[en]Contributor[/en][ja]登録者[/ja]', value: <ItemTypeField.Contributer lang={lang} uname={item.uname} name={item.name} /> },
            { label: '[en]Item Type[/en][ja]アイテムタイプ[/ja]', value: item.item_type_display_name },
            { label: '[en]Change Log(History)[/en][ja]変更履歴[/ja]', value: <ItemTypeField.ChangeLog lang={lang} changelog={item.changelog} /> },
            { label: '[en]Author[/en][ja]著者[/ja]', value: <ItemTypeField.Author lang={lang} author={item.author} /> },
            { label: '[en]Editor[/en][ja]編集者[/ja]', value: Functions.mlang(item.editor, lang) },
            { label: '[en]Publisher[/en][ja]出版社[/ja]', value: Functions.mlang(item.publisher, lang) },
            { label: '[en]Publication Year[/en][ja]出版年[/ja]', value: item.publication_year },
            { label: 'URL', value: <a href={item.url} target="_blank" rel="noopener noreferrer">{item.url}</a> },
            { label: '[en]PDF File[/en][ja]PDF ファイル[/ja]', value: <ItemTypeField.ItemFile lang={lang} file={item.file} type="book_pdf" downloadLimit={item.attachment_dl_limit} /> },
            { label: 'Index', value: <ItemTypeField.ItemIndex lang={lang} index={item.index} /> },
            { label: '[en]Related to[/en][ja]関連アイテム[/ja]', value: <ItemTypeField.RelatedTo lang={lang} relatedTo={item.related_to} /> },
        ];
    }
}

export default BookDetail;

import React from 'react';
import { Link } from 'react-router-dom';
import Functions from '../../../functions';
import iconFile from '../../assets/images/icon_url.gif';
import { ItemUrl } from '../../lib/ItemUtil';
import ListBase, { ListBaseProps } from '../lib/ListBase';

class UrlList extends ListBase {

    constructor(props: ListBaseProps) {
        super(props);
        this.label = 'Url';
        this.icon = iconFile;
    }

    renderBody() {
        const { lang } = this.props;
        const item = this.props.item as ItemUrl;
        return (
            <>
                <Link to={this.url}>{Functions.mlang(item.title, lang)}</Link><br />
                Link to <a href={item.url} target="_blank" rel="noopener noreferrer">{item.url}</a>
            </>
        );
    }
}

export default UrlList;
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import Loading from '../common/lib/Loading';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import ItemType from './item-type';
import ItemUtil, { Item } from './lib/ItemUtil';

interface Params {
    id?: string;
    doi?: string;
}

interface Props extends RouteComponentProps<Params> {
    lang: MultiLang;
}

interface State {
    loading: boolean;
    item: Item | null;
}

class DatabaseDetailItem extends Component<Props, State> {

    private id: number;
    private doi: string;

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            item: null,
        };
        const { params } = this.props.match;
        this.id = typeof params.id !== 'undefined' ? (params.id.match(/^\d+$/) !== null ? parseInt(params.id, 10) : 0) : 0;
        this.doi = typeof params.doi !== 'undefined' ? params.doi : '';
    }

    componentDidMount() {
        this.updateItem();
    }

    componentDidUpdate() {
        const { params } = this.props.match;
        const id = typeof params.id !== 'undefined' ? (params.id.match(/^\d+$/) !== null ? parseInt(params.id, 10) : 0) : 0;
        const doi = typeof params.doi !== 'undefined' ? params.doi : '';
        if (this.id !== id || this.doi !== doi) {
            this.id = id;
            this.doi = doi;
            this.updateItem();
        }
    }

    updateItem() {
        if (this.doi !== '') {
            ItemUtil.getByDoi(this.doi, (item) => {
                this.setState({ loading: false, item });
            });
        } else if (this.id !== 0) {
            ItemUtil.get(this.id, (item) => {
                this.setState({ loading: false, item });
            });
        }
    }

    render() {
        const { lang } = this.props;
        if (this.state.loading) {
            return <Loading />;
        }
        if (this.state.item === null) {
            return <PageNotFound lang={lang} />;
        }
        return (
            <>
                <Helmet>
                    <title>{Functions.mlang(this.state.item.title, lang)} - {Functions.mlang('[en]Database[/en][ja]データベース[/ja]', lang)} - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <h3>{Functions.mlang('[en]Item Detail[/en][ja]アイテム詳細[/ja]', lang)}</h3>
                <br />
                <ItemType.Detail lang={lang} item={this.state.item} />
            </>
        );
    }
}

export default DatabaseDetailItem;
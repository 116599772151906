import { ItemStimulusSubTypes } from '../../lib/ItemUtil';
import TopBase, { TopBaseProps } from '../lib/TopBase';
import iconFile from '../../assets/images/icon_stimulus.gif';

class StimulusTop extends TopBase {

    constructor(props: TopBaseProps) {
        super(props);
        this.type = 'stimulus';
        this.label = 'Stimulus';
        this.icon = iconFile;
        this.description = '[en]Picture, movie and program files for experimental stimuli.[/en][ja]実験用刺激プログラム/スクリプト[/ja]';
        this.subTypes = ItemStimulusSubTypes;
    }
}

export default StimulusTop;
import React from 'react';
import { ItemBasicLang } from '../../../lib/ItemUtil';
import { MultiLang } from '../../../../config';
import Functions from '../../../../functions';

interface Props {
    lang: MultiLang;
    itemLang: ItemBasicLang;
}

const Language = (props: Props) => {
    const { lang, itemLang } = props;
    const langStr = {
        eng: '[en]English[/en][ja]英語[/ja]',
        jpn: '[en]Japanese[/en][ja]日本語[/ja]',
        fra: '[en]French[/en][ja]フランス語[/ja]',
        deu: '[en]German[/en][ja]ドイツ語[/ja]',
        esl: '[en]Spanish[/en][ja]スペイン語[/ja]',
        ita: '[en]Italian[/en][ja]イタリア語[/ja]',
        dut: '[en]Dutch[/en][ja]オランダ語[/ja]',
        sve: '[en]Swedish[/en][ja]スウェーデン語[/ja]',
        nor: '[en]Norwegian[/en][ja]ノルウェー語[/ja]',
        dan: '[en]Danish[/en][ja]デンマーク語[/ja]',
        fin: '[en]Finnish[/en][ja]フィンランド語[/ja]',
        por: '[en]Portuguese[/en][ja]ポルトガル語[/ja]',
        chi: '[en]Chinese[/en][ja]中国語[/ja]',
        kor: '[en]Korean[/en][ja]韓国語[/ja]',
    }
    if (!(itemLang in langStr)) {
        return null;
    }
    return (<span>{Functions.mlang(langStr[itemLang], lang)}</span>);
}

export default Language;
import FilesTop from './FilesTop';
import FilesList from './FilesList';
import FilesDetail from './FilesDetail';
import FilesAdvancedSearch from './FilesAdvancedSearch';

const ItemTypeFiles = {
    Top: FilesTop,
    List: FilesList,
    Detail: FilesDetail,
    AdvancedSearch: FilesAdvancedSearch,
};

export default ItemTypeFiles;
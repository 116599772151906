type AdvancedSearchQueryData = Map<string, URLSearchParams>;

class AdvancedSearchQuery {

    private dataset: AdvancedSearchQueryData = new Map();

    empty() {
        let ret = true;
        this.dataset.forEach((data) => {
            data.forEach((value) => {
                value = value.trim();
                if (value.length !== 0) {
                    ret = false;
                }
            });
        });
        return ret;
    }

    set(type: string, key: string, value: string) {
        type = type.trim();
        key = key.trim();
        const data: URLSearchParams = this.dataset.has(type) ? this.dataset.get(type) as URLSearchParams : new URLSearchParams();
        data.set(key, value);
        this.dataset.set(type, data);
    }

    delete(type: string, key: string) {
        type = type.trim();
        key = key.trim();
        const data: URLSearchParams = this.dataset.has(type) ? this.dataset.get(type) as URLSearchParams : new URLSearchParams();
        data.delete(key);
        this.dataset.set(type, data);
    }

    deleteType(type: string) {
        type = type.trim();
        this.dataset.delete(type.trim());
    }

    getQueryParams() {
        let ret: URLSearchParams = new URLSearchParams();
        this.dataset.forEach((data, type) => {
            data.forEach((value, key) => {
                value = value.trim();
                value.length > 0 && ret.set(type + '.' + key, value);
            });
        });
        return ret;
    }

    setByQueryString(queryString: string) {
        const query = new URLSearchParams(queryString);
        query.forEach((v, k) => {
            const [type, ...key] = k.split('.');
            if (typeof key === 'undefined') {
                return;
            }
            this.set(type, key.join('.'), v);
        });
    }

    getSearchFilter() {
        let filter: any = [];
        this.dataset.forEach((data, type) => {
            let filter2: any = [];
            filter2.push({
                item_type_name: 'xnp' + type,
            });
            data.forEach((value, key) => {
                const regex = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                const file = key.match(/^file\.([^.]+)\.([^.]+)$/);
                if (file !== null) {
                    const fileType = file[1];
                    const subKey = file[2];
                    // TODO: wait to support $elemMatch operation on lokijs
                    // - see: https://github.com/techfort/LokiJS/issues/736
                    // const filter3: any = {
                    //     file_type_name: fileType,
                    //     [subKey]: { '$regex': regex }
                    // };
                    // filter2.push({ file: { '$elemMatch': filter3 } });
                    filter2.push({ 'file.file_type_name': fileType });
                    filter2.push({ ['file.' + subKey]: { '$regex': [regex, 'i'] } });
                } else {
                    filter2.push({ [key]: { '$regex': [regex, 'i'] } });
                }
            });
            filter.push({ '$and': filter2 });
        });
        return { '$or': filter };
    }
}

export default AdvancedSearchQuery;
import { ItemDataSubTypes } from '../../lib/ItemUtil';
import TopBase, { TopBaseProps } from '../lib/TopBase';
import iconFile from '../../assets/images/icon_data.gif';

class DataTop extends TopBase {

    constructor(props: TopBaseProps) {
        super(props);
        this.type = 'data';
        this.label = 'Data';
        this.icon = iconFile;
        this.description = '[en]Result data in numerical text/image/movie formats.[/en][ja]実験結果の数値データ/画像/動画など[/ja]';
        this.subTypes = ItemDataSubTypes;
    }
}

export default DataTop;
import React from 'react';
import Functions from '../../../functions';
import { ItemConference } from '../../lib/ItemUtil';
import DetailBase from '../lib/DetailBase';
import ItemTypeField from '../lib/field';
import ConferenceUtil from './ConferenceUtil';

class ConferenceDetail extends DetailBase {

    getFields() {
        const { lang } = this.props;
        const item = this.props.item as ItemConference;
        return [
            { label: 'ID', value: item.doi },
            { label: '[en]Language[/en][ja]言語[/ja]', value: <ItemTypeField.Language lang={lang} itemLang={item.lang} /> },
            { label: '[en]Conference Title[/en][ja]学会名[/ja]', value: Functions.mlang(item.conference_title, lang) },
            { label: '[en]Place[/en][ja]開催地[/ja]', value: item.place },
            { label: '[en]Date[/en][ja]日付[/ja]', value: <ConferenceUtil.ConferenceDate lang={lang} item={item} /> },
            { label: '[en]Last Modified Date[/en][ja]最終更新日[/ja]', value: <ItemTypeField.DateTime lang={lang} date={item.last_update_date} /> },
            { label: '[en]Created Date[/en][ja]作成日[/ja]', value: <ItemTypeField.DateTime lang={lang} date={item.creation_date} /> },
            { label: '[en]Contributor[/en][ja]登録者[/ja]', value: <ItemTypeField.Contributer lang={lang} uname={item.uname} name={item.name} /> },
            { label: '[en]Item Type[/en][ja]アイテムタイプ[/ja]', value: item.item_type_display_name },
            { label: '[en]Change Log(History)[/en][ja]変更履歴[/ja]', value: <ItemTypeField.ChangeLog lang={lang} changelog={item.changelog} /> },
            { label: '[en]Presentation Title[/en][ja]発表議題[/ja]', value: Functions.mlang(item.title, lang) },
            { label: '[en]Author[/en][ja]発表者[/ja]', value: <ItemTypeField.Author lang={lang} author={item.author} /> },
            { label: '[en]Abstract[/en][ja]要約[/ja]', value: <ItemTypeField.Description lang={lang} description={item.abstract} /> },
            { label: '[en]Presentation File[/en][ja]発表資料[/ja]', value: <ItemTypeField.ItemFile lang={lang} file={item.file} type="conference_file" /> },
            { label: '[en]Presentation Type[/en][ja]発表資料ファイル形式[/ja]', value: <ConferenceUtil.PresentationType lang={lang} type={item.presentation_type} /> },
            { label: '[en]Conference Paper[/en][ja]学会資料[/ja]', value: <ItemTypeField.ItemFile lang={lang} file={item.file} type="conference_paper" /> },
            { label: 'Index', value: <ItemTypeField.ItemIndex lang={lang} index={item.index} /> },
            { label: '[en]Related to[/en][ja]関連アイテム[/ja]', value: <ItemTypeField.RelatedTo lang={lang} relatedTo={item.related_to} /> },
        ];
    }
}

export default ConferenceDetail;
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { MultiLang } from '../config';
import Functions from '../functions';
import DatabaseListItem from './lib/DatabaseListItem';
import ItemUtil, { SearchCallbackFunc, SortCondition } from './lib/ItemUtil';

interface Params {
    itemType: string;
    subItemType?: string;
}

interface Props extends RouteComponentProps<Params> {
    lang: MultiLang;
}

interface State {
    itemType: string;
    subItemType: string;
}

class DatabaseSearchByItemType extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const { params } = this.props.match;
        this.state = {
            itemType: params.itemType ? params.itemType : '',
            subItemType: params.subItemType ? params.subItemType : '',
        };
        this.searchFunc = this.searchFunc.bind(this);
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const { params } = nextProps.match;
        const item_type = params.itemType ? params.itemType : '';
        const sub_item_type = params.subItemType ? params.subItemType : '';
        if (prevState.itemType !== item_type || prevState.subItemType !== sub_item_type) {
            return { item_type, sub_item_type };
        }
        return null;
    }

    searchFunc(condition: SortCondition, func: SearchCallbackFunc) {
        if (this.state.itemType === '') {
            const res = { total: 0, data: [] };
            func(res);
        } else {
            ItemUtil.getListByItemType(this.state.itemType, this.state.subItemType, condition, func);
        }
    }

    getUrl() {
        let url = ItemUtil.getItemTypeSearchUrl(this.state.itemType);
        if (this.state.subItemType !== '') {
            url += '/' + this.state.subItemType;
        }
        return url;
    }

    render() {
        const { lang } = this.props;
        const baseUrl = this.getUrl();
        return (
            <div className="list">
                <h3>{Functions.mlang('[en]Listing item[/en][ja]アイテム一覧[/ja]', lang)}</h3>
                <DatabaseListItem lang={lang} url={baseUrl} search={this.searchFunc} />
            </div>
        );
    }
}

export default DatabaseSearchByItemType;

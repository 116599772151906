import PresentationTop from './PresentationTop';
import PresentationList from './PresentationList';
import PresentationDetail from './PresentationDetail';
import PresentationAdvancedSearch from './PresentationAdvancedSearch';

const ItemTypePresentation = {
    Top: PresentationTop,
    List: PresentationList,
    Detail: PresentationDetail,
    AdvancedSearch: PresentationAdvancedSearch,
};

export default ItemTypePresentation;
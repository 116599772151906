import UrlTop from './UrlTop';
import UrlList from './UrlList';
import UrlDetail from './UrlDetail';
import UrlAdvancedSearch from './UrlAdvancedSearch';

const ItemTypeUrl = {
    Top: UrlTop,
    List: UrlList,
    Detail: UrlDetail,
    AdvancedSearch: UrlAdvancedSearch,
};

export default ItemTypeUrl;

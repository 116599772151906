import ConferenceTop from './ConferenceTop';
import ConferenceList from './ConferenceList';
import ConferenceDetail from './ConferenceDetail';
import ConferenceAdvancedSearch from './ConferenceAdvancedSearch';

const ItemTypeConference = {
    Top: ConferenceTop,
    List: ConferenceList,
    Detail: ConferenceDetail,
    AdvancedSearch: ConferenceAdvancedSearch,
};

export default ItemTypeConference;
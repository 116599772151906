import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { MultiLang } from '../config';
import AdvancedSearchQuery from './lib/AdvancedSearchQuery';
import DatabaseListItem from './lib/DatabaseListItem';
import ItemUtil, { SearchCallbackFunc, SortCondition } from './lib/ItemUtil';

interface Props extends RouteComponentProps {
    lang: MultiLang
}

interface State {
    search: string;
    query: AdvancedSearchQuery;
}

class DatabaseSearchByAdvancedKeyword extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const search = props.location.search;
        const query = ItemUtil.getAdvancedSearchQueryByQuery(search);
        this.state = { search, query };
        this.searchFunc = this.searchFunc.bind(this);
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const search = nextProps.location.search;
        if (prevState.search !== search) {
            const query = ItemUtil.getAdvancedSearchQueryByQuery(search);
            return { search, query };
        }
        return null;
    }

    getUrl() {
        return ItemUtil.getSearchByAdvancedKeywordsUrl(this.state.query);
    }

    searchFunc(condition: SortCondition, func: SearchCallbackFunc) {
        ItemUtil.getListByAdvancedSearchQuery(this.state.query, condition, func);
    }

    render() {
        const { lang } = this.props;
        const baseUrl = this.getUrl();
        return (
            <div className="list">
                <h3>Listing item</h3>
                <DatabaseListItem lang={lang} url={baseUrl} search={this.searchFunc} />
            </div>
        );
    }
}

export default DatabaseSearchByAdvancedKeyword;

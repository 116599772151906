import React from 'react';
import { Link } from 'react-router-dom';
import Functions from '../../../functions';
import iconFile from '../../assets/images/icon_tool.gif';
import { ItemTool } from '../../lib/ItemUtil';
import ListBase, { ListBaseProps } from '../lib/ListBase';
import ToolUtil from './ToolUtil';

class ToolList extends ListBase {

    constructor(props: ListBaseProps) {
        super(props);
        this.label = 'Tool';
        this.icon = iconFile;
    }

    renderBody() {
        const { lang } = this.props;
        const item = this.props.item as ItemTool;
        return (
            <>
                <Link to={this.url}>{Functions.mlang(item.title, lang)}</Link><br />
                <ToolUtil.ToolType lang={lang} type={item.tool_type} />
            </>
        );
    }
}

export default ToolList;
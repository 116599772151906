import React from 'react';
import { MultiLang } from '../../../../config';

export type CreativeCommonsType = 'by' | 'by-nc' | 'by-nc-nd' | 'by-nc-sa' | 'by-nd' | 'by-sa';

export const getCreativeCommonsType = (ccCommercialUse: number, ccModification: number): CreativeCommonsType => {
    const cc = ccCommercialUse * 10 + ccModification;
    switch (cc) {
        case 0:
            return 'by-nc-nd';
        case 1:
            return 'by-nc-sa';
        case 2:
            return 'by-nc';
        case 10:
            return 'by-nd';
        case 11:
            return 'by-sa';
        case 12:
        default:
            return 'by';
    }
}

interface Props {
    lang: MultiLang;
    type: CreativeCommonsType;
}

const CreativeCommons = (props: Props) => {
    const { type } = props;
    const url = 'http://creativecommons.org/licenses/' + type + '/4.0/';
    const logoUrl = 'https://i.creativecommons.org/l/' + type + '/4.0/88x31.png';
    const labels = {
        by: 'Attribution',
        nc: 'NonCommercial',
        nd: 'NoDerivatives',
        sa: 'ShareAlike',
    }
    const label = type.split('-').map((value) => {
        const prop = value as 'by' | 'nc' | 'nd' | 'sa';
        return labels[prop];
    }).join('-');
    return (
        <table style={{borderCollapse: 'separate', borderSpacing: '5px'}}>
            <tbody>
                <tr>
                    <td><a href={url} target="_blank" rel="license noopener noreferrer"><img alt="Creative Commons License" src={logoUrl} /></a></td>
                    <td>This work is licensed under a <a href={url} target="_blank" rel="license noopener noreferrer">Criative Commons {label} 4.0 International License</a>.</td>
                </tr>
            </tbody>
        </table>
    );
}

export default CreativeCommons;
import React from 'react';
import { ItemConferenceSubTypes } from '../../lib/ItemUtil';
import AdvancedSearchBase, { AdvancedSearchBaseProps } from '../lib/AdvancedSearchBase';

class ConferenceAdvancedSearch extends AdvancedSearchBase {

    constructor(props: AdvancedSearchBaseProps) {
        super(props);
        this.type = 'conference';
        this.title = 'Conference';
        const now = new Date();
        const year = String(now.getFullYear());
        const month = String(now.getMonth() + 1);
        const mday = String(now.getDate());
        this.state.values['title'] = '';
        this.state.values['presentation_type'] = '';
        this.state.values['author'] = '';
        this.state.values['conference_from_year'] = year;
        this.state.values['conference_from_month'] = month;
        this.state.values['conference_from_mday'] = mday;
        this.state.values['conference_to_year'] = year;
        this.state.values['conference_to_month'] = month;
        this.state.values['conference_to_mday'] = mday;
        this.setIgnoreKey('conference_from_year');
        this.setIgnoreKey('conference_from_month');
        this.setIgnoreKey('conference_from_mday');
        this.setIgnoreKey('conference_to_year');
        this.setIgnoreKey('conference_to_month');
        this.setIgnoreKey('conference_to_mday');
    }

    renderDate() {
        return (
            <>
                <div>
                    {this.renderFieldDate('From', 'conference_from_year', 'conference_from_month', 'conference_from_mday')}

                </div>
                <div>
                    {this.renderFieldDate('To', 'conference_to_year', 'conference_to_month', 'conference_to_mday')}
                </div>
            </>
        );
    }

    getRows() {
        const rows = [
            { label: '[en]Presentation Title[/en][ja]発表議題[/ja]', value: this.renderFieldInputText('title', 50) },
            { label: '[en]Presentation Type[/en][ja]発表資料ファイル形式[/ja]', value: this.renderFieldSelect('presentation_type', ItemConferenceSubTypes) },
            { label: '[en]Author[/en][ja]発表者[/ja]', value: this.renderFieldInputText('author', 50) },
            { label: '[en]Date[/en][ja]日付[/ja]', value: this.renderDate() }
        ];
        return rows;
    }
}

export default ConferenceAdvancedSearch;
import React, { ChangeEvent, Component } from 'react';
import { MultiLang } from '../../../config';
import Functions from '../../../functions';
import AdvancedSearchQuery from '../../lib/AdvancedSearchQuery';
import { ItemSubTypes } from '../../lib/ItemUtil';

export interface AdvancedSearchBaseProps {
    lang: MultiLang;
    query: AdvancedSearchQuery;
}

interface State {
    show: boolean;
    values: any;
}

class AdvancedSearchBase extends Component<AdvancedSearchBaseProps, State> {

    protected type: string = 'base'
    protected title: string = 'Base';
    protected query: AdvancedSearchQuery;
    protected ignoreKeys: string[] = [];

    constructor(props: AdvancedSearchBaseProps) {
        super(props);
        this.state = {
            show: false,
            values: {},
        }
        this.query = props.query;
        this.handleChangeTitleCheck = this.handleChangeTitleCheck.bind(this);
    }

    updateQuery(key: string, value: string) {
        if (this.ignoreKeys.includes(key)) {
            this.query.delete(this.type, key);
        } else {
            this.query.set(this.type, key, value);
        }
    }

    setIgnoreKey(key: string) {
        if (!this.ignoreKeys.includes(key)) {
            this.ignoreKeys = this.ignoreKeys.concat(key);
        }
        this.query.delete(this.type, key);
    }

    deleteIgnoreKey(key: string) {
        if (this.ignoreKeys.includes(key)) {
            this.ignoreKeys = this.ignoreKeys.filter((v) => {
                return (v !== key);
            });
        }
        this.query.set(this.type, key, this.state.values[key]);
    }

    updateField(key: string, value: string) {
        let values = Object.assign({}, this.state.values);
        values[key] = value;
        this.updateQuery(key, value);
        this.setState({ values });
    }

    handleChangeTitleCheck(e: ChangeEvent<HTMLInputElement>) {
        const show = e.target.checked;
        if (show) {
            Object.keys(this.state.values).forEach((key) => {
                const value = this.state.values[key];
                this.updateQuery(key, value);
            });
        } else {
            this.query.deleteType(this.type);
        }
        this.setState({ show });
    }

    getRows(): { label: string, value: JSX.Element }[] {
        return [];
    }

    renderFieldInputText(key: string, size: number) {
        const onChange = (e: ChangeEvent<HTMLInputElement>) => {
            this.updateField(key, e.target.value);
        };
        return (
            <input className="fieldInputText" type="text" value={this.state.values[key]} size={size} onChange={onChange} />
        );
    }

    renderFieldSelect(key: string, values: ItemSubTypes<any>) {
        const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
            this.updateField(key, e.target.value);
        };
        const options = values.map(({ type, label }, i) => {
            return <option key={i} value={type}>{label}</option>;
        });
        return (
            <select className="fieldSelect" value={this.state.values[key]} onChange={onChange}>
                <option value=''>Any</option>
                {options}
            </select>
        );
    }

    renderFieldDate(label: string, keyYear: string, keyMonth: string, keyMday: string) {
        const onChange = (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
                this.deleteIgnoreKey(keyYear);
                keyMonth !== '' && this.deleteIgnoreKey(keyMonth);
                keyMday !== '' && this.deleteIgnoreKey(keyMday);
            } else {
                this.setIgnoreKey(keyYear);
                keyMonth !== '' && this.setIgnoreKey(keyMonth);
                keyMday !== '' && this.setIgnoreKey(keyMday);
            }
        };
        const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const monthOptions = month.map((value, i) => {
            return <option key={i} value={i + 1}>{value}</option>;
        })
        let mdayOptions: JSX.Element[] = [];
        for (let i = 1; i <= 31; i++) {
            mdayOptions.push(<option key={i} value={i}>{i}</option>)
        }
        return (
            <div className="fieldDate">
                <input type="checkbox" onChange={onChange} />
                {label.length !== 0 && <label className="fieldDateLabel">{label}</label>}
                {keyMonth !== '' &&
                    <select value={this.state.values[keyMonth]} onChange={(e) => this.updateField(keyMonth, e.target.value)}>
                        {monthOptions}
                    </select>
                }
                {keyMday !== '' &&
                    <select value={this.state.values[keyMday]} onChange={(e) => this.updateField(keyMday, e.target.value)}>
                        {mdayOptions}
                    </select>
                }
                <input type="text" value={this.state.values[keyYear]} size={5} onChange={(e) => this.updateField(keyYear, e.target.value)} />
            </div>
        );
    }

    renderBody() {
        const { lang } = this.props;
        if (this.state.show === false) {
            return null;
        }
        const rows = this.getRows();
        const fields = rows.map((value, idx) => {
            const evenodd = idx % 2 === 0 ? 'even' : 'odd';
            return (
                <tr key={idx}>
                    <td className="head">{Functions.mlang(value.label, lang)}</td>
                    <td className={evenodd}>{value.value}</td>
                </tr>
            );
        });
        return (
            <table className="itemtypeFields outer">
                <tbody>
                    {fields}
                </tbody>
            </table>
        );
    }

    render() {
        return (
            <div className="itemtype">
                <table className="itemtypeName outer">
                    <tbody>
                        <tr>
                            <th align="left">
                                <input type="checkbox" checked={this.state.show} onChange={this.handleChangeTitleCheck} />
                                {this.title}
                            </th>
                        </tr>
                    </tbody>
                </table>
                {this.renderBody()}
            </div>
        );
    }

}

export default AdvancedSearchBase;
import BookTop from './BookTop';
import BookList from './BookList';
import BookDetail from './BookDetail';
import BookAdvancedSearch from './BookAdvancedSearch';

const ItemTypeBook = {
    Top: BookTop,
    List: BookList,
    Detail: BookDetail,
    AdvancedSearch: BookAdvancedSearch,
};

export default ItemTypeBook;

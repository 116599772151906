import iconFile from '../../assets/images/icon_presentation.gif';
import { ItemPresentationSubTypes } from '../../lib/ItemUtil';
import TopBase, { TopBaseProps } from '../lib/TopBase';

class PresentationTop extends TopBase {

    constructor(props: TopBaseProps) {
        super(props);
        this.type = 'presentation';
        this.label = 'Presentation';
        this.icon = iconFile;
        this.description = '[en]Electrical presentation files.[/en][ja]プレゼンテーション　ファイル[/ja]';
        this.subTypes = ItemPresentationSubTypes;
    }
}

export default PresentationTop;
import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import DatabaseListIndex from './lib/DatabaseListIndex';
import DatabaseListItem from './lib/DatabaseListItem';
import IndexUtil, { Index, INDEX_ID_PUBLIC } from './lib/IndexUtil';
import ItemUtil, { SearchCallbackFunc, SortCondition } from './lib/ItemUtil';

interface Params {
    id?: string;
}

export interface Props extends RouteComponentProps<Params> {
    lang: MultiLang;
}

interface State {
    indexId: number;
}

class DatabaseSearchByIndexId extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const { params } = props.match;
        this.state = {
            indexId: params.id ? (params.id.match(/^\d+$/) !== null ? parseInt(params.id, 10) : 0) : INDEX_ID_PUBLIC,
        }
        this.searchFunc = this.searchFunc.bind(this);
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const { params } = nextProps.match;
        const indexId = params.id ? (params.id.match(/^\d+$/) !== null ? parseInt(params.id, 10) : 0) : INDEX_ID_PUBLIC;
        if (prevState.indexId !== indexId) {
            return { indexId };
        }
        return null;
    }

    getUrl() {
        if (this.state.indexId === 0) {
            return '/';
        }
        return IndexUtil.getUrl(this.state.indexId);
    }

    searchFunc(condition: SortCondition, func: SearchCallbackFunc) {
        if (this.state.indexId === 0) {
            const res = { total: 0, data: [] };
            func(res);
        } else {
            ItemUtil.getListByIndexId(this.state.indexId, condition, func);
        }
    }

    render() {
        const { lang } = this.props;
        const index = IndexUtil.get(this.state.indexId);
        if (index === null) {
            return <PageNotFound lang={lang} />;
        }
        const baseUrl = this.getUrl();
        const pIndexes = IndexUtil.getParents(this.state.indexId);
        const parents = pIndexes.map((value: Index) => {
            const url: string = IndexUtil.getUrl(value.id);
            const title = Functions.mlang(value.title, lang);
            return <Fragment key={value.id}>/ <Link to={url}>{title}</Link> </Fragment>;
        });
        const title = pIndexes.map((value) => { return '/' + Functions.mlang(value.title, lang); }).join('');
        return (
            <div className="list">
                <Helmet>
                    <title>{Functions.mlang(title, lang)} - {Functions.mlang('[en]Database[/en][ja]データベース[/ja]', lang)} - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <h3>{Functions.mlang('[en]Listing item[/en][ja]アイテム一覧[/ja]', lang)}</h3>
                <div>{parents}</div>
                <DatabaseListIndex lang={lang} index={index} />
                <DatabaseListItem lang={lang} url={baseUrl} search={this.searchFunc} />
            </div>
        );
    }
}

export default DatabaseSearchByIndexId;

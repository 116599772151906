import React from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../../../config';
import Functions from '../../../../functions';
import IndexUtil from '../../../lib/IndexUtil';
import { ItemBasicIndex } from '../../../lib/ItemUtil';

interface Props {
    lang: MultiLang;
    index: ItemBasicIndex[];
}

const ItemIndex = (props: Props) => {
    const { lang, index } = props;
    if (index.length === 0) {
        return null;
    }
    const elements = index.map((value, idx) => {
        const evenodd = idx % 2 === 0 ? 'even' : 'odd';
        const url = IndexUtil.getUrl(value.index_id);
        return (<tr key={value.index_id}><td className={evenodd}><Link to={url}>{Functions.mlang(value.title, lang)}</Link></td></tr>);
    });
    return (<table><tbody>{elements}</tbody></table>);
}

export default ItemIndex;
import React from 'react';
import { MultiLang } from '../../../../config';
import imageButton from '../../../assets/images/simpf_button.png';

interface Props {
    lang: MultiLang;
    url: string;
    isDetail: boolean;
}

const SimPFLinkIcon = (props: Props) => {
    const { url, isDetail } = props;
    const title = 'Online Simulation';
    const size = isDetail ? 64 : 35;
    if (url === '') {
        return null;
    }
    return (
        <a href={url} target="_blank" rel="noopener noreferrer" title={title}>
            <img src={imageButton} alt={title} width={size} height={size} />
        </a>
    );;
}

export default SimPFLinkIcon;

import StimulusTop from './StimulusTop';
import StimulusList from './StimulusList';
import StimulusDetail from './StimulusDetail';
import StimulusAdvancedSearch from './StimulusAdvancedSearch';

const ItemTypeStimulus = {
    Top: StimulusTop,
    List: StimulusList,
    Detail: StimulusDetail,
    AdvancedSearch: StimulusAdvancedSearch,
};

export default ItemTypeStimulus;
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { ItemConference } from '../../lib/ItemUtil';
import ListBase, { ListBaseProps } from '../lib/ListBase';
import ConferenceUtil from './ConferenceUtil';
import iconFile from '../../assets/images/icon_conference.gif';
import Functions from '../../../functions';

class ConferenceList extends ListBase {

    constructor(props: ListBaseProps) {
        super(props);
        this.label = 'Conference';
        this.icon = iconFile;
    }

    renderBody() {
        const { lang } = this.props;
        const item = this.props.item as ItemConference;
        const authors = item.author.map((author, i) => {
            return <Fragment key={i}>{i > 0 && ', '}{Functions.mlang(author, lang)}</Fragment>
        });
        return (
            <>
                <Link to={this.url}>{Functions.mlang(item.title, lang)}</Link><br />
                {Functions.mlang(item.conference_title, lang)} (<ConferenceUtil.PresentationType lang={lang} type={item.presentation_type} />)<br />
                {authors}
            </>
        );
    }
}

export default ConferenceList;
import TopBase, { TopBaseProps } from '../lib/TopBase';
import iconFile from '../../assets/images/icon_files.gif';
import { ItemFilesSubTypes } from '../../lib/ItemUtil';

class FilesTop extends TopBase {

    constructor(props: TopBaseProps) {
        super(props);
        this.type = 'files';
        this.label = 'Files';
        this.icon = iconFile;
        this.description = '[en]Various type of File.[/en][ja]ファイル[/ja]';
        this.subTypes = ItemFilesSubTypes;
    }
}

export default FilesTop;
import React from 'react';
import { MultiLang } from '../../../../config';

interface Props {
    lang: MultiLang;
    author: string[];
}

const Author = (props: Props) => {
    const { author } = props;
    if (author.length === 0) {
        return null;
    }
    const elements = author.map((value, idx) => {
        const evenodd = idx % 2 === 0 ? 'even' : 'odd';
        return <tr key={idx}><td className={evenodd}>{value}</td></tr>;
    });
    return <table><tbody>{elements}</tbody></table>;
}

export default Author;
import ModelTop from './ModelTop';
import ModelList from './ModelList';
import ModelDetail from './ModelDetail';
import ModelAdvancedSearch from './ModelAdvancedSearch';

const ItemTypeModel = {
    Top: ModelTop,
    List: ModelList,
    Detail: ModelDetail,
    AdvancedSearch: ModelAdvancedSearch,
};

export default ItemTypeModel;
import React from 'react';
import { MultiLang } from '../../../../config';
import Functions from '../../../../functions';
import { ItemBasicFile } from '../../../lib/ItemUtil';
import DateTime from './DateTime';
import FileDownloadButton from './FileDownloadButton';
import FileSize from './FileSize';

interface Props {
    lang: MultiLang;
    file: ItemBasicFile[];
    type: string;
    rights?: string;
    useCc?: number;
    ccCommercialUse?: number;
    ccModification?: number;
    downloadLimit?: number;
}

const ItemFile = (props: Props) => {
    const { lang, file, type } = props;
    const rights = typeof props.rights === 'undefined' ? '' : props.rights;
    const useCc = typeof props.useCc === 'undefined' ? 0 : props.useCc;
    const ccCommercialUse = typeof props.ccCommercialUse === 'undefined' ? 0 : props.ccCommercialUse;
    const ccModification = typeof props.ccModification === 'undefined' ? 0 : props.ccModification;
    const downloadLimit = typeof props.downloadLimit === 'undefined' ? 0 : props.downloadLimit;
    const data = file.find((value) => {
        return value.file_type_name === type;
    });
    if (typeof data === 'undefined') {
        return null;
    }
    const date = new Date(data.timestamp);
    const timestamp = Math.floor(date.valueOf() / 1000);
    return (
        <div>
            {data.original_file_name}<br />
            <table>
                <tbody>
                    <tr><td>Type</td><td>: {data.mime_type}</td><td rowSpan={4}>{downloadLimit === 0 && <FileDownloadButton lang={lang} file={data} rights={rights} useCc={useCc} ccCommercialUse={ccCommercialUse} ccModification={ccModification} />}</td></tr>
                    <tr><td>Size</td><td>: <FileSize lang={lang} size={data.file_size} /></td></tr>
                    <tr><td>Last updated</td><td>: <DateTime lang={lang} date={timestamp} onlyDate={true} /></td></tr>
                </tbody>
            </table>
            {downloadLimit === 1 && <><br />({Functions.mlang('[en]File has been removed[/en][ja]ファイルは削除されました[/ja]', lang)})</>}
        </div>
    );
}

export default ItemFile;
import DataTop from './DataTop';
import DataList from './DataList';
import DataDetail from './DataDetail';
import DataAdvancedSearch from './DataAdvancedSearch';

const ItemTypeData = {
    Top: DataTop,
    List: DataList,
    Detail: DataDetail,
    AdvancedSearch: DataAdvancedSearch,
};

export default ItemTypeData;
import AdvancedSearchBase, { AdvancedSearchBaseProps } from '../lib/AdvancedSearchBase';

class FilesAdvancedSearch extends AdvancedSearchBase {

    constructor(props: AdvancedSearchBaseProps) {
        super(props);
        this.type = 'files';
        this.title = 'Files';
        this.state.values['title'] = '';
        this.state.values['data_file_name'] = '';
        this.state.values['data_file_mimetype'] = '';
        this.state.values['data_file_filetype'] = '';
        this.state.values['keyword'] = '';
        this.state.values['description'] = '';
    }

    getRows() {
        const rows = [
            { label: '[en]Title[/en][ja]タイトル[/ja]', value: this.renderFieldInputText('title', 50) },
            { label: '- [en]File Name[/en][ja]ファイル名[/ja]', value: this.renderFieldInputText('data_file_name', 50) },
            { label: '- [en]MIME Type[/en][ja]MIMEタイプ[/ja]', value: this.renderFieldInputText('data_file_mimetype', 50) },
            { label: '- [en]File Type[/en][ja]ファイルタイプ[/ja]', value: this.renderFieldInputText('data_file_filetype', 20) },
            { label: '[en]Free Keywords[/en][ja]フリーキーワード[/ja]', value: this.renderFieldInputText('keyword', 50) },
            { label: '[en]Description[/en][ja]概要[/ja]', value: this.renderFieldInputText('description', 50) },
        ];
        return rows;
    }
}

export default FilesAdvancedSearch;
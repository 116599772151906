import PaperTop from './PaperTop';
import PaperList from './PaperList';
import PaperDetail from './PaperDetail';
import PaperAdvancedSearch from './PaperAdvancedSearch';

const ItemTypePaper = {
    Top: PaperTop,
    List: PaperList,
    Detail: PaperDetail,
    AdvancedSearch: PaperAdvancedSearch,
};

export default ItemTypePaper;
import SimulatorTop from './SimulatorTop';
import SimulatorList from './SimulatorList';
import SimulatorDetail from './SimulatorDetail';
import SimulatorAdvancedSearch from './SimulatorAdvancedSearch';

const ItemTypeSimulator = {
    Top: SimulatorTop,
    List: SimulatorList,
    Detail: SimulatorDetail,
    AdvancedSearch: SimulatorAdvancedSearch,
};

export default ItemTypeSimulator;
import React, { Component } from 'react';
import { MultiLang } from '../../../../config';
import ItemUtil, { ItemBasicFile } from '../../../lib/ItemUtil';
import styles from './FileDownloadButton.module.css';
import LicenseAgreementDialog from './LicenseAgreementDialog';

interface Props {
    lang: MultiLang;
    file: ItemBasicFile;
    rights: string;
    useCc: number;
    ccCommercialUse: number;
    ccModification: number;
}

interface State {
    show: boolean;
}

class FileDownloadButton extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            show: false,
        };
        this.handleClickDownload = this.handleClickDownload.bind(this);
        this.unsetShow = this.unsetShow.bind(this);
    }

    handleClickDownload(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        if (this.props.rights !== '') {
            e.stopPropagation();
            e.preventDefault();
            this.setState({ show: true });
        }
    }

    unsetShow() {
        this.setState({ show: false });
    }

    render() {
        const { lang, file, rights, useCc, ccCommercialUse, ccModification } = this.props;
        const url = ItemUtil.getFileUrl(this.props.file);
        return (
            <>
                <a className={styles.downloadButton} href={url} download={file.original_file_name} target="_blank" rel="noopener noreferrer" onClick={this.handleClickDownload}>Download</a>
                <LicenseAgreementDialog lang={lang} file={file} rights={rights} useCc={useCc} ccCommercialUse={ccCommercialUse} ccModification={ccModification} show={this.state.show} unsetShow={this.unsetShow} />
            </>
        );
    }
}

export default FileDownloadButton;
import React from 'react';
import { MultiLang } from '../../../config';
import { ItemStimulusSubType, ItemStimulusSubTypes } from '../../lib/ItemUtil';

interface StimulusTypeProps {
    lang: MultiLang;
    type: ItemStimulusSubType;
}

const StimulusType = (props: StimulusTypeProps) => {
    const { type } = props;
    const subtype = ItemStimulusSubTypes.find((value) => { return value.type === type; });
    if (typeof subtype === 'undefined') {
        return null;
    }
    return (<span>{subtype.label}</span>);
}

const StimulusUtil = {
    StimulusType,
}

export default StimulusUtil;
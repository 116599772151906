import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Functions from '../../../functions';
import iconFile from '../../assets/images/icon_stimulus.gif';
import { ItemStimulus } from '../../lib/ItemUtil';
import ListBase, { ListBaseProps } from '../lib/ListBase';

class StimulusList extends ListBase {

    constructor(props: ListBaseProps) {
        super(props);
        this.label = 'Stimulus';
        this.icon = iconFile;
    }

    renderBody() {
        const { lang } = this.props;
        const item = this.props.item as ItemStimulus;
        const authors = item.developer.map((author, i) => {
            return <Fragment key={i}>{i > 0 && ', '}{Functions.mlang(author, lang)}</Fragment>
        });
        return (
            <>
                <Link to={this.url}>{Functions.mlang(item.title, lang)}</Link><br />
                {authors}
            </>
        );
    }
}

export default StimulusList;
import React, { ChangeEvent, Component, FormEvent } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Config, { MultiLang } from '../../config';
import Functions from '../../functions';
import ItemUtil, { KeywordSearchType } from '../lib/ItemUtil';

interface Props extends RouteComponentProps {
    lang: MultiLang;
}

interface State {
    type: KeywordSearchType;
    keyword: string;
    pathname: string;
}

class Search extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            type: 'all',
            keyword: '',
            pathname: '',
        };
        this.handleChangeType = this.handleChangeType.bind(this);
        this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const pathname = nextProps.location.pathname;
        if (pathname !== prevState.pathname) {
            if (pathname === '/database/search') {
                const { type, keyword } = ItemUtil.getSearchKeywordByQuery(nextProps.location.search);
                return { type, keyword, pathname };
            }
            return {
                type: prevState.type,
                keyword: prevState.keyword,
                pathname
            };
        }
        return null;
    }

    handleChangeKeyword(event: ChangeEvent<HTMLInputElement>) {
        const keyword = event.target.value.trim();
        this.setState({ keyword });
    }

    handleChangeType(event: ChangeEvent<HTMLSelectElement>) {
        const type = event.target.value as KeywordSearchType;
        this.setState({ type });
    }

    handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const url = ItemUtil.getSearchByKeywordUrl(this.state.type, this.state.keyword);
        this.props.history.push(url);
    }

    render() {
        const { lang } = this.props;
        const options = [
            { value: 'all', label: '[en]All[/en][ja]全て[/ja]' },
            { value: 'basic', label: '[en]Title & Keyword[/en][ja]タイトル＆キーワード[/ja]' },
        ];
        Config.XOONIPS_ITEMTYPES.forEach((type) => {
            options.push({value: type, label: Functions.pascalCase(type)})
        });
        return (
            <form onSubmit={this.handleSubmit}>
                <input style={{width: '170px'}} type="text" value={this.state.keyword} onChange={this.handleChangeKeyword} />
                &nbsp;&nbsp;
                <select value={this.state.type} onChange={this.handleChangeType}>
                    {options.map((option) => {
                        return <option key={option.value} value={option.value}>{Functions.mlang(option.label, lang)}</option>;
                    })}
                </select>
                <br />
                <input className="formButton" type="submit" value="Search" />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Link to="/database/advanced">{Functions.mlang('[en]Advanced[/en][ja]詳細検索[/ja]', lang)}</Link>
            </form>
        );
    }
}

export default withRouter(Search);
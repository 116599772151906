import React from 'react';
import Config, { MultiLang } from '../config';
import styles from './DatabaseTop.module.css';
import ItemType from './item-type';

interface Props {
    lang: MultiLang;
}

const DatabaseTop = (props: Props) => {
    const { lang } = props;
    const types: string[][] = [];
    const len = Config.XOONIPS_ITEMTYPES.length;
    for (let i = 0; i < Math.ceil(len / 2); i++) {
        const j = i * 2;
        const p = Config.XOONIPS_ITEMTYPES.slice(j, j + 2);
        types.push(p);
    }
    return (
        <table className={styles.itemTypes}>
            <tbody>
                {types.map((value, idx) => {
                    return (
                        <tr key={idx}>
                            {value.map((type, idx) => {
                                return (
                                    <td key={idx} className={styles.itemType}>
                                        {type !== '' && <ItemType.Top lang={lang} type={'xnp' + type} />}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default DatabaseTop;

import React from 'react';
import { Link } from 'react-router-dom';
import XoopsCode from '../../../common/lib/XoopsCode';
import Functions from '../../../functions';
import iconFile from '../../assets/images/icon_memo.gif';
import { ItemMemo } from '../../lib/ItemUtil';
import ListBase, { ListBaseProps } from '../lib/ListBase';

class MemoList extends ListBase {

    constructor(props: ListBaseProps) {
        super(props);
        this.label = 'Memo';
        this.icon = iconFile;
    }

    renderBody() {
        const { lang } = this.props;
        const item = this.props.item as ItemMemo;
        const link = item.item_link !== '' ? <XoopsCode lang={lang} text={item.item_link} /> : null;
        return (
            <>
                <Link to={this.url}>{Functions.mlang(item.title, lang)}</Link><br />
                {link}
            </>
        );
    }
}

export default MemoList;

import React, { Component, ReactNode } from 'react';
import { MultiLang } from '../../../config';
import Functions from '../../../functions';
import { Item } from '../../lib/ItemUtil';

export interface DetailBaseField {
    label: string;
    value: ReactNode;
}

export interface DetailBaseProps {
    lang: MultiLang;
    item: Item
}

class DetailBase extends Component<DetailBaseProps> {

    getFields(): DetailBaseField[] {
        return [];
    }

    render() {
        const { lang } = this.props;
        const elements = this.getFields().map((value, idx) => {
            const evenodd = idx % 2 === 0 ? 'even' : 'odd';
            return (
                <tr key={idx}>
                    <td className="head">{Functions.mlang(value.label, lang)}</td>
                    <td className={evenodd}>{value.value}</td>
                </tr>
            )
        });
        return (
            <table className="outer itemDetail">
                <tbody>
                    {elements}
                </tbody>
            </table>
        );
    }
}

export default DetailBase;
import React from 'react';
import { MultiLang } from '../../../config';
import { ItemToolSubType, ItemToolSubTypes } from '../../lib/ItemUtil';

interface ToolTypeProps {
    lang: MultiLang;
    type: ItemToolSubType;
}

const ToolType = (props: ToolTypeProps) => {
    const { type } = props;
    const subtype = ItemToolSubTypes.find((value) => { return value.type === type; });
    if (typeof subtype === 'undefined') {
        return null;
    }
    return (<span>{subtype.label}</span>);
}

const ToolUtil = {
    ToolType,
}

export default ToolUtil;
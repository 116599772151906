import MemoTop from './MemoTop';
import MemoList from './MemoList';
import MemoDetail from './MemoDetail';
import MemoAdvancedSearch from './MemoAdvancedSearch';

const ItemTypeMemo = {
    Top: MemoTop,
    List: MemoList,
    Detail: MemoDetail,
    AdvancedSearch: MemoAdvancedSearch,
};

export default ItemTypeMemo;
import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { MultiLang } from '../../../../config';
import Functions from '../../../../functions';
import ItemUtil, { ItemBasicFile } from '../../../lib/ItemUtil';
import styles from './Preview.module.css';

interface Props {
    lang: MultiLang;
    file: ItemBasicFile[];
}

interface State {
    isOpen: boolean;
    imageIndex: number;
}

class Preview extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isOpen: false,
            imageIndex: 0,
        };
    }

    render() {
        const { lang, file } = this.props;
        const data = file.filter((value) => {
            return value.file_type_name === 'preview';
        });
        if (data.length === 0) {
            return null;
        }
        let imageUrls: string[] = [];
        const previews = data.map((value, idx) => {
            const fileUrl = ItemUtil.getFileUrl(value);
            const previewUrl = ItemUtil.getPreviewFileUrl(value);
            const caption = Functions.mlang(value.caption, lang);
            imageUrls.push(fileUrl);
            return (
                <figure key={value.file_id} className={styles.preview}>
                    <a href={fileUrl} download={value.original_file_name} onClick={(e) => {
                        e.preventDefault();
                        this.setState({ isOpen: true, imageIndex: idx });
                    }}>
                        <img src={previewUrl} alt={caption} />
                    </a>
                    <figcaption>{caption}</figcaption>
                </figure>
            );
        });
        const { isOpen, imageIndex } = this.state;
        return (
            <>
                <div className={styles.previewBox}>
                    {previews}
                </div>
                {isOpen &&
                    <Lightbox
                        mainSrc={imageUrls[imageIndex]}
                        nextSrc={imageUrls[(imageIndex + 1) % data.length]}
                        prevSrc={imageUrls[(imageIndex + data.length - 1) % data.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                imageIndex: (imageIndex + data.length - 1) % data.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                imageIndex: (imageIndex + 1) % data.length,
                            })
                        }
                    />
                }
            </>
        );
    }
}

export default Preview;
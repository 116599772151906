import AdvancedSearchBase, { AdvancedSearchBaseProps } from '../lib/AdvancedSearchBase';

class MemoAdvancedSearch extends AdvancedSearchBase {

    constructor(props: AdvancedSearchBaseProps) {
        super(props);
        this.type = 'memo';
        this.title = 'Memo';
        this.state.values['title'] = '';
        this.state.values['keyword'] = '';
        this.state.values['description'] = '';
        this.state.values['doi'] = '';
        this.state.values['item_link'] = '';
    }

    getRows() {
        const rows = [
            { label: '[en]Title[/en][ja]タイトル[/ja]', value: this.renderFieldInputText('title', 50) },
            { label: '[en]Free Keywords[/en][ja]フリーキーワード[/ja]', value: this.renderFieldInputText('keyword', 50) },
            { label: '[en]Description[/en][ja]概要[/ja]', value: this.renderFieldInputText('description', 50) },
            { label: 'ID', value: this.renderFieldInputText('doi', 50) },
            { label: '[en]Item Link[/en][ja]リンク[/ja]', value: this.renderFieldInputText('item_link', 50) },
        ];
        return rows;
    }
}

export default MemoAdvancedSearch;

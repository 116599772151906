import React from 'react';
import Functions from '../../../functions';
import { ItemUrl } from '../../lib/ItemUtil';
import DetailBase from '../lib/DetailBase';
import ItemTypeField from '../lib/field';
import UrlUtil from './UrlUtil';

class UrlDetail extends DetailBase {

    getFields() {
        const { lang } = this.props;
        const item = this.props.item as ItemUrl;
        return [
            { label: 'ID', value: item.doi },
            { label: '[en]Language[/en][ja]言語[/ja]', value: <ItemTypeField.Language lang={lang} itemLang={item.lang} /> },
            { label: '[en]Title[/en][ja]タイトル[/ja]', value: Functions.mlang(item.title, lang) },
            { label: '[en]Free Keywords[/en][ja]フリーキーワード[/ja]', value: <ItemTypeField.FreeKeyword lang={lang} keyword={item.keyword} /> },
            { label: '[en]Description[/en][ja]概要[/ja]', value: <ItemTypeField.Description lang={lang} description={item.description} /> },
            { label: '[en]Last Modified Date[/en][ja]最終更新日[/ja]', value: <ItemTypeField.DateTime lang={lang} date={item.last_update_date} /> },
            { label: '[en]Created Date[/en][ja]作成日[/ja]', value: <ItemTypeField.DateTime lang={lang} date={item.creation_date} /> },
            { label: '[en]Contributor[/en][ja]登録者[/ja]', value: <ItemTypeField.Contributer lang={lang} uname={item.uname} name={item.name} /> },
            { label: '[en]Item Type[/en][ja]アイテムタイプ[/ja]', value: item.item_type_display_name },
            { label: '[en]Change Log(History)[/en][ja]変更履歴[/ja]', value: <ItemTypeField.ChangeLog lang={lang} changelog={item.changelog} /> },
            { label: 'URL', value: <a href={item.url} target="_blank" rel="noopener noreferrer">{item.url}</a> },
            { label: '[en]Banner File[/en][ja]バナー[/ja]', value: <UrlUtil.BannerFile lang={lang} file={item.file} /> },
            { label: 'Index', value: <ItemTypeField.ItemIndex lang={lang} index={item.index} /> },
            { label: '[en]Related to[/en][ja]関連アイテム[/ja]', value: <ItemTypeField.RelatedTo lang={lang} relatedTo={item.related_to} /> },
        ];
    }
}

export default UrlDetail;
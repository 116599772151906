import ToolTop from './ToolTop';
import ToolList from './ToolList';
import ToolDetail from './ToolDetail';
import ToolAdvancedSearch from './ToolAdvancedSearch';

const ItemTypeTool = {
    Top: ToolTop,
    List: ToolList,
    Detail: ToolDetail,
    AdvancedSearch: ToolAdvancedSearch,
};

export default ItemTypeTool;